import { useStyles } from '../styles'
import React, {
  useState,
  useEffect,
} from 'react'
import CountrySelectInput from 'containers/CountrySelectInput'
import { Controller, useFormContext } from 'react-hook-form'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import { Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'

const CountryComponent = ({ nameId, formItem: { multiple, label, visibleIfTrue, options, info, required } }) => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const {
    control,
    setValue,
    watch,
  } = useFormContext()
  const [show, setShow] = useState(true)

  useEffect(() => {
    if (!show) {
      setShow(true)
    }
  }, [show])

  const isHidden = visibleIfTrue && watch(visibleIfTrue, 'NO') !== 'YES'

  return (
    <>
    <Controller
      name={nameId}
      control={control}
      render={({ field, fieldState }) => (
        <CountrySelectInput
          {...field}
          dense
          multiple={multiple}
          {...options && { options }}
          label={label + (required ? ' *' : '')}
          fullWidth
          variant="outlined"
          valueKey="alpha2"
          className={[
            classes.field,
            isHidden && classes.hidden,
          ]}
          error={!!fieldState.error}
          helperText={`${fieldState.error ? formatMessage({ id: 'countries.is-required' }) : ''}`}
          onDelete={(_value) => {
            const value = watch(nameId)
            if (value.length === 1) {
              return setValue(nameId, multiple ? [] : '')
            }
            setValue(
              nameId,
              value.filter((v) => v !== _value),
            )
          }}
          endAdornment={
            <InputAdornment position="end" className={classes.selectClear}>
              <IconButton
                disableRipple
                onClick={() => {
                  setValue(nameId, multiple ? [] : '')
                  setShow(false)
                }}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                <ClearIcon fontSize="small" color="disabled" />
              </IconButton>
            </InputAdornment>
          }
        />
      )}
    />
    {info && !isHidden && <Typography color="textSecondary" className={classes.info} dangerouslySetInnerHTML={{ __html: info }} />}
    </>
  )
}

export default CountryComponent